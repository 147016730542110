$font-size-small: 0.75rem;
$font-size-normal: 0.875rem;
$font-size-large: 1.125rem;
$font-size-huge: 1.5rem;

.customSize {
    :global {
        .ql-editor {
            padding: 0.5rem;
            font-size: $font-size-normal;
        }

        .ql-size-small {
            font-size: $font-size-small
        }

        .ql-size-large {
            font-size: $font-size-large;
        }

        .ql-size-huge {
            font-size: $font-size-huge;
        }

        .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
            font-size: $font-size-small;
        }

        .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
            font-size: $font-size-large;
        }

        .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
            font-size: $font-size-huge;
        }

        span > img {
            display: inline;
            max-width: 100%;
            height: auto;
            margin: 0;
        }

        h2 {
            margin-top: 0.5rem;
        }
    }
}
.qlWrapper {
    :global {

        .ql-container {
            border-bottom-left-radius: 0.375rem;
            border-bottom-right-radius: 0.375rem;
            padding: 0rem;

            .ql-editor.ql-blank::before {
                color: rgb(163 168 179);
                font-style: normal;
                display: block;
                /* needed to allow for the below margin */
                margin-bottom: -1.42em; /* pulls content below the placeholder up to match the position: absolute behavior where content is on the same line as the placeholder; 1.42 matches the line-height of the editor */
                position: static;
            }
        }

        .ql-toolbar {
            padding: 0.3rem;
            border-top-left-radius: 0.375rem;
            border-top-right-radius: 0.375rem;
        }
    }
}